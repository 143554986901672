<template>
  <div>
    <button
      class="vm-modal__close"
      @click="$emit('close')"
      :title="strings.close"
    >
      <span uk-icon="icon: close; ratio: 1.5"></span>
    </button>

    <div class="uk-box-shadow-medium uk-margin-bottom card ">
      <div class="uk-background-muted uk-padding body">
        <div
          class="category uk-margin uk-margin-remove-top uk-width-small"
          v-if="category"
        >
          <img :src="_vaData.apiBase + category.logo" alt="category.title" />
        </div>
        <h1 v-if="title" v-html="title" class="body__title"></h1>
        <div v-if="subtitle" v-html="subtitle" class="body__subtitle"></div>
        <div v-if="body">
          <vue-markdown>
            {{ body }}
          </vue-markdown>
        </div>
      </div>
    </div>

    <Slide
      class="uk-background-default uk-box-shadow-medium uk-margin"
      v-for="slide in slides"
      :key="slide.id"
      :slide="slide"
    ></Slide>
    <div class="uk-background-default uk-padding uk-margin-medium-bottom">
      <div
        class="uk-child-width-expand uk-margin-remove-top uk-margin-remove-left "
        uk-grid
      >
        <div v-if="product_link" class="finder_link__button uk-button">
          <a
            id="Productinfo"
            class="finder_link uk-button uk-width-1-1"
            :href="product_link"
            target="_blank"
            ><span uk-icon="icon: world" class=""></span
          ></a>
        </div>
        <div v-if="contact_person_link" class="finder_link__button uk-button">
          <a
            id="Contact"
            class="finder_link uk-button uk-width-1-1"
            :href="contact_person_link"
            target="_blank"
            ><span uk-icon="icon: user" class=""></span
          ></a>
        </div>
      </div>
      <div
        class="uk-child-width-expand uk-margin-remove-top uk-margin-remove-left "
        uk-grid
      >
        <CallToContact class="callToContact" :referer="title"></CallToContact>
        <div v-if="strings.booking_link" class="finder_link__button uk-button">
          <a
            id="Booking"
            class="finder_link uk-button uk-width-1-1"
            :href="strings.booking_link"
            target="_blank"
          >
            <span uk-icon="icon: calendar" class=""></span
          ></a>
        </div>
        <div v-if="strings.modal_number" class="finder_link__button uk-button">
          <a
            id="Phone_Number"
            class="finder_link uk-button uk-width-1-1"
            :href="strings.modal_number"
            target="_blank"
          >
            <span
              uk-icon="icon: receiver"
              class=""
              :href="strings.modal_number"
            ></span
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slide from "./Slide.vue";
import CallToContact from "./CallToContact.vue";

import { bus } from "../../main";
// optional style for arrows & dots
export default {
  name: "ModalDefault_mobile",
  data: function() {
    return {
      strings: this._vaData.strings
    };
  },
  props: {
    title: String,
    subtitle: String,
    category: Object,
    body: String,
    booking_link: String,
    product_link: String,
    contact_person_link: String,
    slides: Array
  },
  components: {
    Slide,

    CallToContact
  },
  methods: {
    loadIframe() {}
  },
  mounted() {
    // open all links in modal in new tab
    document.querySelectorAll(".c-va_modal__container a").forEach($link => {
      $link.addEventListener("click", e => {
        e.preventDefault();
        window.open(e.target.href);
        return;
      });
    });
    bus.$emit("slide_showed", {
      title: this.title,
      content: {
        title: this.title
      }
    });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.callToContact {
  padding: 15px;
}
.finder_link__button {
  @extend .uk-background-default;
  padding: 15px;
  a {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    padding: 0.9em;
    line-height: 1.4;
    width: 100%;
  }
  button {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    padding: 0.9em !important;
    line-height: 1.4;
    width: 100%;
  }
}
.finder_link {
  @extend .uk-button, .uk-button-primary, .uk-button-large;
}
.body__title {
  margin-bottom: 0;
  padding-top: 5px;
  word-break: break-word;
}
.body__subtitle {
  @extend .uk-margin;
}

.modal-functions {
  .uk-button {
    padding: 0.5em 1em;
    line-height: 1.4;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
