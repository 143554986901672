<template>
  <div class="uk-nav-dropdown">
    <button class="uk-button uk-button-primary uk-button--mobile" type="button">
      {{ title }}
    </button>
    <vue-custom-scrollbar
      class="dropdown"
      :uk-dropdown="position ? 'pos:' + position : '' + '; mode:click;'"
      :settings="scrollbar.settings"
    >
      <ul class="uk-nav uk-dropdown-nav">
        <li
          v-for="(entry, index) in entries"
          :key="index"
          @click="onEntryClick(entry)"
        >
          <a href="#">{{ entry.title }}</a>
        </li>
      </ul>
    </vue-custom-scrollbar>
  </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
export default {
  name: "UkNavDropdown",
  props: {
    title: String,
    entries: Array,
    position: String
  },
  data: function() {
    return {
      scrollbar: {
        settings: {
          suppressScrollY: false,
          suppressScrollX: true,
          wheelPropagation: false
        }
      }
    };
  },
  components: {
    vueCustomScrollbar
  },

  methods: {
    onEntryClick(entry) {
      this.$emit("onEntryClick", entry);
    }
  }
};
</script>

<style lang="scss" scoped>
.uk-nav-dropdown {
  button {
    font-size: 1.2em;
  }
  * {
    pointer-events: all;
  }
  @media (max-width: 960px) and (max-height: 600px) {
    button {
      font-size: 0.8em;
      line-height: 25px;
      padding: 0 10px;
    }
  }
}
.dropdown {
  max-height: 66vh;
  overflow: hidden;
  overflow-y: scroll;
  user-select: none;
  max-width: calc(85vw - 2 * 25px);

  ul {
    a {
      color: $global-muted-color;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
