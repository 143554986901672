<template>
  <div class="videothing">
    <!-- here -->
  </div>
</template>

<script>
import VideoThing from "videoThing";
import "../../../node_modules/videoThing/dist/videoThing.css";
import { bus } from "../../main";

export default {
  name: "VideoThing",
  props: {
    settings: Object
  },
  created() {
    bus.$on("go_back", () => {
      this.videoThing.goBack();
    });
    bus.$on("show_hotspots", () => {
      this.videoThing.showHotspots();
    });
    bus.$on("hide_hotspots", () => {
      this.videoThing.hideHotspots();
    });
    bus.$on("show_navigation", () => {
      this.videoThing.showNavigation();
    });
    bus.$on("hide_navigation", () => {
      this.videoThing.hideNavigation();
    });
    bus.$on("show_menus", () => {
      this.videoThing.showMenus();
    });
    bus.$on("hide_menus", () => {
      this.videoThing.hideMenus();
    });
    bus.$on("go_to_location", id => {
      this.videoThing.goToLocation(id);
    });
  },
  methods: {},
  mounted() {
    const elem = document.querySelector(".videothing");
    this.videoThing = new VideoThing(
      elem,
      this._vaData.locations,
      this._vaData.destinations,
      this.settings
    );

    this.videoThing.on("location_reached", id => {
      bus.$emit("location_reached", id, this.videoThing.history);

      // this.$nextTick(() => {
      //
      //   // if last location is new location, remove it from history
      //   const history = this.videoThing.history;
      //   if (history.length > 1 && history[history.length - 1] === history[history.length - 2]) {
      //     this.videoThing.history.pop();
      //   }
      // })
    });

    this.videoThing.on("location_left", id => {
      bus.$emit("location_left", id, this.videoThing.history);
    });

    this.videoThing.on("custom_button_clicked", (e, item) => {
      bus.$emit("show_content", item.target_product.id);
    });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
body,
html {
  &.is-mobile {
    .controls {
      .button {
        &.mobile__button--hide {
          display: none !important;
        }
      }
    }
  }
}
.videothing {
  $hotspot-background: $global-emphasis-color;
  $navigation-background: $global-emphasis-color;

  .controls {
    .button-container {
      .button {
        @media (max-width: 1200px) {
          // margin-bottom: 25em !important;
        }
      }
    }
    .button {
      padding: 0.5em 0.7em;
      user-select: none;
      text-align: center;

      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 15px;
      white-space: normal;

      content: "";

      &.btn-visible {
        background-color: $navigation-background;
        color: #fff;
        &:hover {
          background-color: $navigation-background;
          filter: brightness(120%);
        }

        @media (max-width: 1200px) {
        }
        @media (max-width: $breakpoint-small) {
        }
      }
      &.btn-invisible {
        font-size: 0px;
        background: transparent;
        color: transparent;
        width: 200px;
        height: 100px;

        // border: 2px solid red !important;

        @media (max-width: 1200px) {
          width: 110px;
        }
        @media (max-width: $breakpoint-small) {
        }
      }

      &.btn-topseller {
        width: 450px;
        height: 170px;
        @media (min-width: 2000px) {
          width: 510px;
          height: 200px;
        }
        @media (max-width: 1600px) {
          width: 380px;
          height: 120px;
        }
        @media (max-width: 1400px) {
          width: 300px;
          height: 100px;
        }
        @media (max-width: 1200px) {
          width: 240px;
          height: 80px;
        }
        @media (max-width: 1000px) {
          width: 200px;
          height: 70px;
        }
        @media (max-width: 800px) {
          width: 170px;
          height: 55px;
        }
        @media (max-width: 680px) {
          width: 150px;
          height: 50px;
        }
      }
      &.btn-markets {
        width: 260px;
        height: 260px;
        @media (min-width: 2000px) {
          width: 350px;
          height: 270px;
        }
        @media (max-width: 1600px) {
          width: 220px;
          height: 160px;
        }
        @media (max-width: 1400px) {
          width: 220px;
          height: 160px;
        }
        @media (max-width: 1200px) {
          width: 180px;
          height: 140px;
        }
        @media (max-width: 1000px) {
          width: 140px;
          height: 120px;
        }
        @media (max-width: 800px) {
          width: 120px;
          height: 100px;
        }
        @media (max-width: 680px) {
          width: 110px;
          height: 80px;
        }
      }
      &.btn-info {
        width: 430px;
        height: 140px;
        @media (min-width: 2000px) {
          width: 480px;
          height: 150px;
        }
        @media (max-width: 1600px) {
          width: 300px;
          height: 100px;
        }
        @media (max-width: 1400px) {
          width: 260px;
          height: 90px;
        }
        @media (max-width: 1200px) {
          width: 220px;
          height: 70px;
        }
        @media (max-width: 1000px) {
          width: 160px;
          height: 60px;
        }
        @media (max-width: 800px) {
          width: 140px;
          height: 50px;
        }
        @media (max-width: 680px) {
          width: 120px;
          height: 45px;
        }
      }
      &.btn-news {
        width: 210px;
        height: 155px;
        @media (min-width: 2000px) {
          width: 240px;
          height: 150px;
        }
        @media (max-width: 1600px) {
          width: 190px;
          height: 120px;
        }
        @media (max-width: 1400px) {
          width: 140px;
          height: 110px;
        }
        @media (max-width: 1200px) {
          width: 120px;
          height: 90px;
        }
        @media (max-width: 1000px) {
          width: 80px;
          height: 70px;
        }
        @media (max-width: 800px) {
          width: 70px;
          height: 60px;
        }
        @media (max-width: 680px) {
          width: 60px;
          height: 50px;
        }
      }
      &.btn-cinema {
        width: 290px;
        height: 140px;
        @media (min-width: 2000px) {
          width: 320px;
          height: 160px;
        }
        @media (max-width: 1600px) {
          width: 230px;
          height: 140px;
        }
        @media (max-width: 1400px) {
          width: 180px;
          height: 125px;
        }
        @media (max-width: 1200px) {
          width: 150px;
          height: 100px;
        }
        @media (max-width: 1000px) {
          width: 120px;
          height: 80px;
        }
        @media (max-width: 800px) {
          width: 100px;
          height: 60px;
        }
        @media (max-width: 680px) {
          width: 90px;
          height: 50px;
        }
      }
      &.btn-service {
        width: 270px;
        height: 120px;
        @media (min-width: 2000px) {
          width: 300px;
          height: 130px;
        }
        @media (max-width: 1600px) {
          width: 200px;
          height: 90px;
        }
        @media (max-width: 1400px) {
          width: 180px;
          height: 75px;
        }
        @media (max-width: 1200px) {
          width: 140px;
          height: 60px;
        }
        @media (max-width: 1000px) {
          width: 120px;
          height: 50px;
        }
        @media (max-width: 800px) {
          width: 90px;
          height: 45px;
        }
        @media (max-width: 680px) {
          width: 80px;
          height: 40px;
        }
      }
      &.btn-campus {
        width: 220px;
        height: 115px;
        @media (min-width: 2000px) {
          width: 260px;
          height: 150px;
        }
        @media (max-width: 1600px) {
          width: 170px;
          height: 110px;
        }
        @media (max-width: 1400px) {
          width: 130px;
          height: 90px;
        }
        @media (max-width: 1200px) {
          width: 120px;
          height: 75px;
        }
        @media (max-width: 1000px) {
          width: 90px;
          height: 60px;
        }
        @media (max-width: 800px) {
          width: 70px;
          height: 50px;
        }
        @media (max-width: 680px) {
          width: 60px;
          height: 40px;
        }
      }

      &.button_size1 {
        width: 180px;
        font-size: 14px;
        height: 30px;

        @media (max-width: 1200px) {
          width: 155px;
          font-size: 12px;
        }
        @media (max-width: $breakpoint-small) {
        }
        @media (max-width: 960px) {
          width: 120px;
          font-size: 10px;
        }
      }

      &.button_size2 {
        width: 160px;
        font-size: 14px;
        height: 30px;

        @media (max-width: 1200px) {
          width: 140px;
          font-size: 12px;
        }
        @media (max-width: $breakpoint-large) {
        }
      }

      &.btn_left {
        margin-left: 80px !important;

        @media (max-width: 1200px) {
          margin-left: 20px !important;
        }
        @media (max-width: 900px) {
          margin-left: 0 !important;
        }
        @media (max-width: 960px) and (max-height: 600px) {
          margin-right: 40px !important;
        }
      }
      &.btn_right {
        margin-right: 80px !important;

        @media (max-width: 1200px) {
          margin-right: 20px !important;
        }
        @media (max-width: 900px) {
          margin-right: 0 !important;
        }
        @media (max-width: 960px) and (max-height: 600px) {
          margin-left: 40px !important;
        }
      }

      &.btn_mobile-markets--left {
        @media (max-width: $breakpoint-large) {
          margin-bottom: 40px !important;
          margin-right: 400px !important;
        }
      }

      &.btn_mobile-markets--right {
        @media (max-width: $breakpoint-large) {
          margin-bottom: 62px !important;
        }
      }
      &.btn-categorie {
        pointer-events: none;
        background-color: $global-muted-color;
        color: #000;
        font-weight: 600;
        @media (max-width: 1200px) {
          display: none;
        }
        &:before {
          content: "";
          position: absolute;
          bottom: -1em;
          left: calc(20% - 1em);
          border-left: 1em solid transparent;
          border-right: 1em solid transparent;
          border-top: 1em solid $global-muted-color;
        }
      }

      &[class*="btn-dot"] {
        height: 40px;
        width: 40px;
        box-sizing: border-box;
        border-radius: 50%;
        position: relative;
        background: transparent !important;
        box-shadow: none !important;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          border-radius: 50%;
          transform: scale(1);
          animation: pulse 2s infinite;
          background-color: $global-emphasis-color;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 50%;
        }

        .button__text {
          display: none;
        }
      }
      @media (max-width: 1200px) {
        &.btn-dot--play {
          margin-right: 10px !important;
        }
        &.btn-dot--info {
          margin-left: 10px !important;
        }
      }
      @media (max-width: 960px) {
        &[class*="btn-dot"] {
          height: 30px;
          width: 30px;
        }
        &.btn-dot--play {
          margin-right: 25px !important;
        }
        &.btn-dot--info {
          margin-left: 25px !important;
        }
      }

      &.btn-dot--play:after {
        background-image: url("../../assets/play.svg");
      }
      &.btn-dot--plus:after {
        background-image: url("../../assets/plus.svg");
      }
      &.btn-dot--info:after {
        background-image: url("../../assets/info.svg");
      }
      &.btn-dot--left:after {
        background-image: url("../../assets/chevron-left.svg");
      }
      &.btn-dot--right:after {
        background-image: url("../../assets/chevron-right.svg");
      }

      &.mobile__button--hide {
        @media (max-width: 1200px) {
          display: none !important;
        }
      }
      &.mobile__button--show {
        display: none !important;
        @media (max-width: 1200px) {
          display: block !important;
        }
      }

      &[class*="button__mobile"] {
        @media (max-width: 1200px) {
          height: 30px;
          width: 30px;
          box-sizing: border-box;
          border-radius: none;
          position: relative;
          background: transparent !important;
          // margin-bottom: 25em !important;
          bottom: 100px;

          &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: none;
            background-color: $global-emphasis-color;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 70%;
          }

          .button__text {
            display: none;
          }
        }
      }

      &.button__mobile--more:after {
        background-image: url("../../assets/more.svg");
      }
      &.button__mobile--left:after {
        background-image: url("../../assets/chevron-left.svg");
      }
      &.button__mobile--right:after {
        background-image: url("../../assets/chevron-right.svg");
      }

      &[class*="btn-square"] {
        height: 30px;
        width: 30px;
        box-sizing: border-box;
        border-radius: none;
        position: relative;
        background: transparent !important;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          border-radius: none;
          // transform: scale(1);
          // animation: pulse 2s infinite;
          background-color: $global-emphasis-color;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 80%;
        }

        .button__text {
          display: none;
        }
      }

      &.btn-square--left:after {
        background-image: url("../../assets/chevron-left.svg");
      }
      &.btn-square--right:after {
        background-image: url("../../assets/chevron-right.svg");
      }

      &.button_new {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        line-height: 15px;
        white-space: normal;
      }

      &[data-type="hotspot"] {
        background-color: $hotspot-background;
        color: #fff;

        &:hover {
          background-color: $hotspot-background;
          filter: brightness(120%);
        }
      }

      &[data-type="navigation"] {
        // color: #fff;
        &:hover {
        }
      }
      &.btn-expand--width {
        width: auto;
      }
      &.btn-lookNice {
        .button__text {
          padding-left: 3px;
          padding-right: 3px;
        }
      }

      &.btn_bold {
        .button__text {
          font-weight: 600;
        }
      }
    }
    .button span {
      max-width: 100%;
      max-height: 100%;
      text-align: center;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
}
</style>
