<template>
  <div class="uk-position-relative slide">
    <div v-if="slide.title || slide.subtitle" class="header">
      <h3 class="slide__title" v-if="slide.title">{{ slide.title }}</h3>
      <span class="slide__subtitle" v-if="slide.subtitle">{{
        slide.subtitle
      }}</span>
    </div>
    <vue-custom-scrollbar :settings="scrollbar.settings" class="slide__body">
      <div class="slide__media">
        <div v-if="!slide.src" class="media">
          <!-- Fallback -->
          <img src="fallback.jpg" alt="Asset not found" />
        </div>

        <template v-else>
          <div v-if="slide.type === 'video'" class="media">
            <video
              :data-autoplay="slide.video_autoplay"
              :data-videomute="slide.video_mute"
              class="uk-width-1-1"
              controls
              :poster="_vaData.apiBase + slide.poster"
              :src="_vaData.apiBase + slide.src"
            ></video>
          </div>

          <div v-if="slide.type === 'image'" class="media">
            <img :src="_vaData.apiBase + slide.src" :alt="slide.title" />
          </div>

          <div v-if="slide.type === 'iframe'" class="media">
            <div class="vx-ratio-box media_iframe uk-background-muted">
              <iframe :src="slide.src" :allow="'fullscreen'"></iframe>
            </div>
            <div v-if="slide.qrcode_usdz" class="body qrcode_img slide__qrcode">
              <div v-if="is_usdz()">
                <div class="usdz_link__button uk-button">
                  <a
                    class="usdz_link usdz_link__button uk-button"
                    :href="slide.directlink_usdz"
                    target="_blank"
                    title="Contact Person"
                    >{{ strings.description_usdz }}</a
                  >
                </div>
              </div>
              <div v-else>
                <img
                  :src="_vaData.apiBase + slide.qrcode_usdz.url"
                  :alt="slide.title"
                />
                <div class="qrcode_subline">{{ strings.description_usdz }}</div>
              </div>
            </div>
          </div>

          <div v-if="slide.caption" class="caption">
            <span>{{ slide.caption }}</span>
          </div>
        </template>
      </div>
      <div v-if="slide.body" class="uk-padding body">
        <vue-markdown>{{ slide.body }}</vue-markdown>
      </div>
    </vue-custom-scrollbar>
  </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";

export default {
  name: "Slide",
  components: {
    vueCustomScrollbar
  },
  mounted() {
    const isSmall = window.innerWidth < 960;
    this.scrollbar.settings.swicher = !isSmall;
    console.log(this.$props.slide);
  },
  data() {
    return {
      strings: this._vaData.strings,
      scrollbar: {
        settings: {
          suppressScrollY: false,
          suppressScrollX: true,
          wheelPropagation: true,
          swicher: true
        }
      }
    };
  },
  props: {
    slide: Object,
    directlink_usdz: String
  },
  methods: {
    is_usdz: () => {
      const a = document.createElement("a");
      if (a.relList.supports("ar")) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.header {
  @extend .uk-padding;
  padding-bottom: 15px;
  padding-right: 70px;
  @media (min-width: 960px) {
    height: 60px;
  }
}
.slide__title {
  margin-bottom: 0;
}

.slide__subtitle {
  text-transform: uppercase;
}

.slide__media {
  position: relative;
}
.slide__qrcode {
  position: relative;
}
.slide__body {
  @extend .uk-flex-1;
  // max-height: calc(70vh - 65px - 105px - 5.5px);
  max-height: calc(85vh - 175.5px);
  overflow-y: scroll;
  @media (min-width: $breakpoint-large) {
    max-height: calc(85vh - 125.5px);
  }
  // @media (min-height: 850px) {
  //     max-height: calc(85vh - 186.5px)
  // }
  @media (max-width: 960px) {
    max-height: 100%;
  }
}

.media {
  position: relative;
  text-align: center;
}

.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 0.5em 1em;
  font-size: 11px;
  line-height: 1.5;
  // color: #fff;
  span {
    position: relative;
    z-index: 1;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    right: 0;
    background-color: #fff;
    opacity: 0.5;
  }
}
.media_iframe {
  padding-top: 56.25%;
  // padding-top: 40.25%;
}
body {
  &.is-mobile {
    .media_iframe {
      height: 400px;
    }
    .slide__qrcode {
      position: absolute;
      right: 50%;
      bottom: 5%;
      transform: translateX(50%);

      padding: 0px;
    }
    .qrcode_subline {
      top: 115%;
      width: 250px;
    }
    .usdz_link__button {
      a {
        margin-left: 0px;
      }
    }
  }
}
.slide__qrcode {
  position: absolute;
  right: 5%;
  bottom: 5%;
  padding-right: 50px;
  padding: 30px;
}
.qrcode_img {
  img {
    width: 100px !important;
    height: 100px !important;
  }
}
.qrcode_subline {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
  font-size: 12px;
  line-height: 1.2em;
  width: 150px;
}
.usdz_link__button {
  @extend .uk-padding;
  a {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    padding: 0.9em;
    line-height: 1.4;
    width: 100%;
    margin: 0;
    margin-left: 30px;
  }

  @media (max-width: 1200px) {
    a {
      font-size: 12px;
    }
  }
}
.usdz_link {
  @extend .uk-button, .uk-button-primary, .uk-button-large;
}
</style>
