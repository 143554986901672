<template>
  <div class="title">
    <div class="uk-margin-top btn-back">
      <transition
        name="fade"
        enter-active-class="fadeInUp"
        leave-active-class="fadeOutDown"
      >
        <button
          v-if="parentLocation"
          class="back-btn"
          @click="goToLocation(parentLocation.id)"
          :title="parentLocation.title"
        >
          <i uk-icon="chevron-left"></i> {{ strings.back }}
          <span>&#8205;</span>
        </button>
      </transition>
    </div>
  </div>
</template>
<script>
import { bus } from "../main";

export default {
  name: "Footer",
  data() {
    return {
      strings: this._vaData.strings,
      locationTitle: null,
      parentLocation: null
    };
  },
  props: {
    showBackBtn: Boolean
  },
  computed: {
    currentTitle: function() {
      return this.locations;
    }
  },
  methods: {
    goBack: () => {
      bus.$emit("go_back");
    },
    goToLocation: id => {
      bus.$emit("go_to_location", id);
    }
  },
  created() {
    // this.locationTitle = this._vaData.locations.find(l => l.id === this._vaData.settings.start_location).title;

    bus.$on("location_reached", id => {
      const location = this._vaData.locations.find(l => l.id === id);
      this.locationTitle = location.title;
      this.parentLocation = location.parent_location;
    });

    bus.$on("location_left", () => {
      this.locationTitle = null;
      this.parentLocation = null;
    });
  },
  components: {}
};
</script>
<style lang="scss" scoped>
$animationDuration: 0.5s;
// specify animation duration. Default value: 1s
@import "@/../node_modules/vue2-animate/src/sass/vue2-animate.scss";
.btn-back {
  pointer-events: all;
  // margin-left: 1em;
  // padding-left: 1em;
  position: absolute;
  bottom: 0;
}
.back-btn {
  pointer-events: all;
  @extend .uk-button;
  @extend .uk-button-primary;
  z-index: 1000;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  font-size: 12px;
  bottom: 0;
  text-transform: uppercase;
  align-items: center;
  white-space: nowrap;
  margin-right: 0.5em;
  background-color: $global-emphasis-color;
  color: $global-muted-color;
  i {
    margin-right: 5px;
  }

  @media (max-width: 960px) {
    height: 27px;
    font-size: 0px;
    line-height: 27px;
    padding: 0 5px;
  }
}
</style>
