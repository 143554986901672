import { render, staticRenderFns } from "./LocationMenu.vue?vue&type=template&id=0503ddd8&scoped=true&lang=html&"
import script from "./LocationMenu.vue?vue&type=script&lang=js&"
export * from "./LocationMenu.vue?vue&type=script&lang=js&"
import style0 from "./LocationMenu.vue?vue&type=style&index=0&id=0503ddd8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0503ddd8",
  null
  
)

export default component.exports