<template>
  <div class="contactsMenu" :class="'uk-position-' + position">
    <div class="contactsMenu__container">
      <div v-if="strings.modal_number" class="link">
        <button class=" link__icon">
          <span uk-icon="icon: receiver" class=""></span>
        </button>
        <div uk-dropdown="pos:right-center; mode: click" class="dropdown-small">
          <span class="modal_number" :href="strings.modal_number">{{
            strings.modal_number
          }}</span>
        </div>
      </div>
      <div
        v-for="(link, i) in links"
        :key="i"
        class="link"
        :class="{ 'link--open': i === openLink }"
      >
        <template>
          <a
            class="link__icon"
            :href="link.href"
            :title="link.title"
            :target="'_' + link.target"
            onclick="dataLayer.push({'event': 'termin-button-click'});"
          >
            <i
              v-if="link.icon"
              :style="{
                'background-image': 'url(' + _vaData.apiBase + link.icon + ')'
              }"
            ></i>
          </a>
        </template>
      </div>

      <div v-if="form" class="link">
        <a
          class="link__icon"
          @click="showContactForm"
          :title="form.title"
          onclick="dataLayer.push({'event': 'mail-button-click'});"
        >
          <i :style="{ 'background-image': 'url(./mail-outline.svg)' }"></i>
        </a>
      </div>
      <div v-if="strings.newsletter" class="link">
        <button class=" link__icon">
          <span uk-icon="icon: info" class=""></span>
        </button>
        <div uk-dropdown="pos:right-center; mode: click" class="dropdown-small">
          <a
            class="newsletter_link"
            :href="strings.newsletter_link"
            :title="strings.newsletter"
            target="_blank"
          >
            {{ strings.newsletter }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ContactForm from "./ContactForm.vue";
import { bus } from "../main";
export default {
  name: "ContactMenu",
  data: function() {
    return {
      strings: this._vaData.strings,
      openLink: null,
      links: this._vaData.strings.contact_links,
      form: this._vaData.strings.forms.find(
        f => f.form_id === "default_contact_form"
      )
    };
  },
  props: {
    position: {
      type: String,
      default: "center-left",
      validator: prop =>
        [
          "top-left",
          "center-left",
          "bottom-left",
          "top-right",
          "center-right",
          "bottom-right",
          "top-left"
        ].includes(prop)
    }
  },
  methods: {
    isPhoneLink: function(href) {
      return href.indexOf("tel:") > -1 ? href.replace("tel:", "") : false;
    },
    toggleLink: function(i) {
      this.openLink = this.openLink ? null : i;
    },
    showContactForm() {
      this.form.referer = null;

      this.$modal.show(
        ContactForm,
        {
          form: this.form
        },
        {
          height: "auto",
          adaptive: true,
          scrollable: true
        },
        {
          "before-open": this.beforeOpen
        }
      );
    },
    beforeOpen() {
      bus.$emit("contact_form_opened");
    }
  },
  created() {
    bus.$on("show_contact_form", () => {
      this.showContactForm();
    });

    document.querySelector(".videoapp").addEventListener("click", event => {
      const path = event.path || (event.composedPath && event.composedPath());
      // click wherever to close open link
      if (path.indexOf(document.querySelector(".link--open")) === -1) {
        this.openLink = null;
      }
    });
  }
};
</script>
<style lang="scss" scoped>
$border-radius: 0;
$border-color: $global-emphasis-color;
$text-background: #fff;

.contactsMenu {
  z-index: 2;
  padding: 0;
  @media (orientation: portrait) and (max-width: $breakpoint-large) {
    padding: 0 !important;
  }
}
.contactsMenu__container {
  --btn-size: 50px;
  width: var(--btn-size);
  display: flex;
  flex-direction: column;
  @media (max-width: 960px) {
    --btn-size: 40px;
  }
  @media (max-width: 960px) and (max-height: 600px) {
    --btn-size: 35px;
  }
}

.link {
  display: flex;
  margin: 0.2em 0;
  height: var(--btn-size);

  &.link--open {
    .link__text {
      max-width: 200px;
    }
  }

  .contactsMenu[class*="right"] & {
    flex-direction: row-reverse;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
.link__icon {
  @extend .uk-button;
  @extend .uk-button-primary;

  color: #fff;
  display: block;
  height: var(--btn-size);
  width: var(--btn-size);
  padding: 0;
  margin: 0;
  position: relative;
  flex-shrink: 0;
  border: 0;
  border-left: 2px solid $border-color;

  i {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: center;
    filter: brightness(0) invert(1);
  }

  @media (max-width: 960px) {
    i {
      background-size: 55%;
    }
  }

  @media (max-width: 960px) and (max-height: 600px) {
    i {
      background-size: 50%;
    }
  }
}

.link__text {
  background-color: $text-background;
  color: $global-emphasis-color;
  height: 100%;
  max-width: 0;
  flex-shrink: 0;
  display: flex;
  overflow: hidden;
  transition: all 0.5s;
  text-overflow: ellipsis;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  span {
    margin: 0 1em;
  }

  &:hover {
    color: $global-emphasis-color;
    text-decoration: none;
  }
}
.dropdown-small {
  padding: 14px;
}
.newsletter_link {
  color: #fff;
  text-decoration: none;
  pointer-events: all;
  padding: 10px 20px;

  &:hover {
    color: #fff;
    text-decoration: none;
  }
}
</style>
