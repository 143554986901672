<template>
<div class="videoapp__meta-links">
  <div uk-grid class="uk-grid-small">
    <a v-for="link in links" :key="link.id" class="" :href="link.url" :target="'_' + link.target">{{link.text}}</a>
  </div>
</div>
</template>
<script>
export default {
  name: 'MetaLinks',
  data: function() {
    return {
      links: this._vaData.strings.meta_links
    }
  },
  props: {

  }
}
</script>
<style lang="scss">

</style>
