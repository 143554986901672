<template>
  <div class="entry-box">
    <h4 class="entry-box__title">{{ title }}</h4>
    <div class="uk-inline uk-width-1-1">
      <span class="uk-form-icon" uk-icon="icon: search"></span>
      <input
        class="entry-box__search"
        v-model="entryFilterVal"
        :placeholder="strings.entrybox_search"
      />
    </div>

    <vue-custom-scrollbar
      class="uk-height-max-small uk-width-1-1"
      :settings="scrollbar.settings"
    >
      <ul class="entry-box__list">
        <li
          v-for="entry in filteredEntries"
          :key="entry.id"
          @click="onEntryClick(entry.id)"
        >
          <a href="#" class="uk-link-reset" v-html="entry.title"></a>
        </li>
        <li v-if="filteredEntries.length === 0">no results</li>
      </ul>
    </vue-custom-scrollbar>
  </div>
</template>
<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
export default {
  name: "EntryBox",
  components: {
    vueCustomScrollbar
  },
  data() {
    return {
      strings: this._vaData.strings,
      entryFilterVal: "",
      scrollbar: {
        settings: {
          suppressScrollY: false,
          suppressScrollX: true,
          wheelPropagation: false
        }
      }
    };
  },
  props: {
    title: String,
    entries: Array,
    onEntryClick: Function
  },
  computed: {
    filteredEntries: function() {
      return this.entries
        .filter(
          entry =>
            entry.title
              .toLowerCase()
              .indexOf(this.entryFilterVal.toLowerCase()) > -1
        )
        .sort((a, b) => (a.order > b.order ? 1 : -1));
      // filter(entry => entry.title.toLowerCase().indexOf(this.entryFilterVal.toLowerCase()) > -1)
    }
  },
  methods: {},
  created() {}
};
</script>
<style lang="scss" scoped>
.entry-box {
  border-bottom: 1px solid rgba($global-emphasis-color, 0.1);
  @extend .uk-margin-medium;

  .uk-form-icon {
    color: $global-emphasis-color;
  }

  ::placeholder {
    color: rgba(#fff, 0.6);
  }
}

.entry-box__title {
  @extend .uk-h5;
  margin-top: 0;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
}

.entry-box__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #f2f5f8;
  padding: 8px 0;

  li {
    padding: 0.5em;
    margin-top: 0;

    a {
      color: $global-emphasis-color !important;
      width: 100%;
      display: inline-block;
    }
    &:hover {
      background: rgba(#fff, 0.025);
    }
  }
}

.entry-box__search {
  height: 35px;
  padding-left: 40px;
  padding-right: 8px;
  width: calc(100% - 48px);
  background-color: rgba($global-emphasis-color, 0.3);
  border: none;
  color: #fff;
}
</style>
