<template>
  <div class="title">
    <div class="language-switcher">
      <div class="uk-grid-small uk-grid-divider" uk-grid>
        <div>
          <a
            href="?lang=de"
            data-lang="de"
            :class="_vaData.currentLanguage === 'de' ? 'current' : ''"
            >de</a
          >
        </div>
        <div>
          <a
            href="?lang=en"
            data-lang="en"
            :class="_vaData.currentLanguage === 'en' ? 'current' : ''"
            >en</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { bus } from "../main";

export default {
  name: "Title",
  data() {
    return {
      strings: this._vaData.strings,
      parentLocation: null,
      locationTitle: null
    };
  },
  props: {
    showBackBtn: Boolean
  },
  computed: {
    currentTitle: function() {
      return this.locations;
    }
  },
  methods: {
    goBack: () => {
      bus.$emit("go_back");
    },
    goToLocation: id => {
      bus.$emit("go_to_location", id);
    }
  },
  created() {
    bus.$on("location_reached", id => {
      const location = this._vaData.locations.find(l => l.id === id);
      this.locationTitle = location.title;
      this.parentLocation = location.parent_location;
    });

    bus.$on("location_left", () => {
      this.locationTitle = null;
      this.parentLocation = null;
    });
  },
  components: {}
};
</script>
<style lang="scss" scoped>
$animationDuration: 0.5s;
// specify animation duration. Default value: 1s
@import "@/../node_modules/vue2-animate/src/sass/vue2-animate.scss";

.btn-parent {
  border: none;
  background-color: $global-emphasis-color;
  background-image: url("../assets/segment.svg");
  background-size: 65%;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  line-height: 1.2;
  min-height: 40px;
  font-size: 13px;
  min-width: 3em;
  padding: 0.3em 0.6em;
  cursor: pointer;
}

.title {
  // margin-top: -.5em;
  @media (orientation: portrait) and (max-width: $breakpoint-large) {
    font-size: 10px;
  }
  @media (orientation: landscape) and (max-width: $breakpoint-large) {
    margin-top: 1em;
    font-size: 11px;
  }
  @media (max-width: 960px) {
    margin-top: 1em;
    margin-left: 0;
    padding-left: 0;
  }
  margin-left: 1em;
  padding-left: 1em;
  margin-top: 1em;
}
h1 {
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
  font-size: 1.8em;
  margin-top: -0.2em;
  @media (max-width: 960px) {
    font-size: 1.6em;
  }
}

h2 {
  margin: 0;
  font-size: 1.4em;
  text-transform: uppercase;
}
.btn-back {
  margin-left: 1em;
  padding-left: 1em;
  position: absolute;
  bottom: 0;
  @media (max-width: 960px) {
    display: none;
  }
}
.back-btn {
  @extend .uk-button;
  @extend .uk-button-primary;
  z-index: 1000;
  height: 35px;
  line-height: 35px;
  padding: 0 15px;
  font-size: 12px;
  bottom: 0;
  text-transform: uppercase;
  align-items: center;
  white-space: nowrap;
  margin-right: 0.5em;
  background-color: $global-muted-color;
  color: $global-emphasis-color;
  i {
    margin-right: 5px;
  }
}

.language-switcher {
  position: absolute;
  right: 110px;
  top: 1.2em;
  padding: 25px;
  font-size: 16px;
  line-height: 0.8;

  @media (orientation: landscape) and (max-width: $breakpoint-large) {
    // static header  on small portrait devices (smartphones)
    padding: 12px;
  }
  @media (max-width: 1920px) {
    padding: 15px;
  }
  @media (max-width: 1200px) {
    padding: 7px;
  }
  @media (max-width: 960px) {
    padding: 2px;
    top: 0.9em;
  }
  @media (max-width: 960px) and (max-height: 600px) {
    top: 0.7em;
    font-size: 14px;
  }

  a {
    color: $global-muted-color;
    text-transform: uppercase;
    transition: all 0.2s;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
    font-size: 1.3em;
    line-height: 1.2em;
    @media (max-width: 960px) {
    }

    &:hover {
      color: $global-secondary-background;
      text-decoration: none;
    }

    &.current {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
</style>
