<template>
<div class="">

  <button class="vm-modal__close" @click="$emit('close')">
    <span uk-icon="icon: close; ratio: 1.5"></span>
  </button>



  <div class="uk-background-default uk-box-shadow-medium m-va_modal__container">

    <VueSlickCarousel v-bind=" slickSettings" ref="carousel">
      <Slide v-for="slide in slides" :key="slide.id" :slide="slide"></Slide>
    </VueSlickCarousel>

  </div>



</div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import Slide from './SlideMedia.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'ModalMedia',
  data: function() {
    return {
      slickSettings: {
        "dots": true,
        "arrows": true
      }
    };
  },
  props: {
    title: String,
    subtitle: String,
    slides: Array
  },
  components: {
    VueSlickCarousel,
    Slide,

  },
  methods: {



  },
  created() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.m-va_modal__container {
    overflow: hidden;
}
</style>
