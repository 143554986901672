<template>
  <div>
    <button
      class="vm-modal__close"
      @click="$emit('close')"
      :title="strings.close"
    >
      <span uk-icon="icon: close; ratio: 1.5"></span>
    </button>

    <div class="c-va_modal__container">
      <div class="aside">
        <div class="aside__header">
          <h2 v-if="title" v-html="title"></h2>
          <h5 v-if="subtitle" v-html="subtitle"></h5>
        </div>

        <vue-custom-scrollbar
          :settings="scrollbar.settings"
          class="aside__body"
        >
          <div v-if="body">
            <vue-markdown>
              {{ body }}
            </vue-markdown>
          </div>
        </vue-custom-scrollbar>

        <div class="modal_contact_padding">
          <div
            class="uk-grid uk-grid-small uk-child-width-expand uk-margin-remove-left"
            uk-grid
          >
            <div v-if="product_link" class="finder_link__button uk-button">
              <a
                id="Productinfo"
                class="finder_link uk-button finder_link--world"
                :href="product_link"
                target="_blank"
                title="Produktlink"
                >{{ strings.product_link }}</a
              >
            </div>
            <div
              v-if="contact_person_link"
              class="finder_link__button uk-button"
            >
              <a
                id="Contact"
                class="finder_link uk-button finder_link--contact"
                :href="contact_person_link"
                target="_blank"
                title="Contact Person"
                >{{ strings.contact_person }}</a
              >
            </div>
          </div>
          <div
            class="uk-grid uk-grid-small uk-child-width-expand uk-margin-remove-top"
            uk-grid
          >
            <CallToContact :referer="title"></CallToContact>
            <div
              v-if="strings.booking_link"
              class="finder_link__button uk-button"
            >
              <a
                id="Booking"
                class="finder_link uk-button finder_link--booking"
                :href="strings.booking_link"
                target="_blank"
              ></a>
            </div>
            <div
              v-if="strings.modal_number"
              class="finder_link__button uk-button"
            >
              <div class="uk-nav-dropdown">
                <button id="Phone_Number" class="finder_link uk-button">
                  <span uk-icon="icon: receiver" class=""></span>
                </button>
                <vue-custom-scrollbar
                  class="dropdown padding-small"
                  uk-dropdown="pos:top-right; mode:click;'
                  "
                  :settings="scrollbar.settings"
                >
                  <span class="modal_number" :href="strings.modal_number">{{
                    strings.modal_number
                  }}</span>
                </vue-custom-scrollbar>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="main">
        <VueSlickCarousel
          v-bind="slickSettings"
          @beforeChange="beforeChangeCarousel"
          @afterChange="afterChangeCarousel"
          @init="onInitCarousel"
          ref="carousel"
        >
          <Slide v-for="slide in slides" :key="slide.id" :slide="slide"></Slide>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import Slide from "./Slide.vue";
import CallToContact from "./CallToContact.vue";
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import { bus } from "../../main";

// optional style for arrows & dots
export default {
  name: "ModalDefault_desktop",
  data: function() {
    return {
      strings: this._vaData.strings,
      slickSettings: {
        dots: true,
        arrows: true
      },
      slideIndex: 0,
      scrollbar: {
        settings: {
          suppressScrollY: false,
          suppressScrollX: true,
          wheelPropagation: false
        }
      }
    };
  },
  props: {
    title: String,
    subtitle: String,
    category: Object,
    body: String,
    slides: Array,
    contact_person_link: String,
    product_link: String,
    link_list: Array
  },
  components: {
    VueSlickCarousel,
    Slide,
    vueCustomScrollbar,
    CallToContact
  },
  created() {},
  mounted() {
    // open all links in modal in new tab
    document.querySelectorAll(".c-va_modal__container a").forEach($link => {
      $link.addEventListener("click", e => {
        e.preventDefault();
        window.open(e.target.href);
        return;
      });
    });
    document
      .querySelectorAll(".c-va_modal__container video")
      .forEach($video => {
        // hide controls of all videos
        $video.controls = "";
      });

    this.afterChangeCarousel();
  },
  methods: {
    onInitCarousel() {
      bus.$emit("slide_showed", {
        index: this.slideIndex,
        content: {
          title: this.title,
          slidetitle: this.slides[this.slideIndex].title
        }
      });
    },
    afterChangeCarousel(i) {
      // this.slideIndex = i;
      if (i >= 0) {
        bus.$emit("slide_showed", {
          index: this.slides[i].id,
          content: {
            title: this.title,
            slidetitle: this.slides[i].title
          }
        });
      }

      const $currentSlide = this.$refs.carousel.$el.querySelector(
        ".slick-current"
      );
      const $video = $currentSlide.querySelector("video");
      const $iframe = $currentSlide.querySelector("iframe");

      if ($video && $video.dataset.autoplay && $video.dataset.videomute) {
        // show controls
        $video.controls = "controls";
        // autoplay
        $video.play();
        // mute video
        $video.muted = true;
      } else if ($video && $video.dataset.autoplay) {
        // show controls
        $video.controls = "controls";
        // autoplay
        $video.play();
      } else if ($video) {
        // show controls
        $video.controls = "controls";
      }

      if ($iframe) {
        if ($iframe.src.indexOf("vimeo") <= -1) {
          console.log("vimeo");
        }
      }
    },
    beforeChangeCarousel() {
      const $currentSlide = this.$refs.carousel.$el.querySelector(
        ".slick-current"
      );
      const $video = $currentSlide.querySelector("video");
      const $iframe = $currentSlide.querySelector("iframe");

      if ($video) {
        $video.pause();
        // hide controls
        $video.controls = "";
      }
      if ($iframe) {
        // const iframeSrc = $iframe.src;
        // $iframe.src = iframeSrc;

        var stopAllYouTubeVideos = () => {
          var iframes = document.querySelectorAll("iframe");
          Array.prototype.forEach.call(iframes, iframe => {
            iframe.contentWindow.postMessage(
              JSON.stringify({ event: "command", func: "stopVideo" }),
              "*"
            );
          });
        };
        stopAllYouTubeVideos();
      }
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.c-va_modal {
  & > div,
  .c-va_modal__container {
    height: 100%;
    overflow: hidden;
  }

  .aside {
    @extend .uk-width-1-3,
      .uk-height-1-1,
      .uk-margin-small-right,
      .uk-box-shadow-medium,
      .uk-flex,
      .uk-flex-column;
    background: #fff;
  }

  .aside__header {
    @extend .uk-padding;
    padding-bottom: 10px;
    color: $global-emphasis-color;
    font-weight: 400;
    position: relative;
    z-index: 1;

    h2 {
      margin: 0;
    }

    h5 {
      margin: 0.5em 0 0;
    }
  }

  .aside__body {
    @extend .uk-padding, .uk-flex-1;
    padding-top: 15px;
    height: 100%;
    overflow-y: scroll;
    font-family: "hobart", arial, sans-serif;
    font-size: 13px;
    line-height: 1.5;
  }

  .aside__footer {
    position: relative;
    z-index: 1;
    @extend .uk-padding;
    padding-bottom: 15px;
    padding-top: 15px;
    border-top: 1px solid $global-muted-color;
    background-color: #efefef;
  }

  .main {
    @extend .uk-width-expand, .uk-background-default, .uk-box-shadow-medium;

    .slick-list,
    .slick-track {
      height: 100%;
    }

    .slick-slide {
      & > div,
      .slide {
        background: #fff;
        height: 100%;
      }
    }

    .slide {
    }
  }
}
.finder_link__button {
  @extend .uk-background-default;
  @extend .uk-padding;
  a {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    padding: 0.9em;
    line-height: 1.4;
    width: 100%;
    margin: 30px 0;
  }
  button {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    padding: 0.9em;
    line-height: 1.4;
    width: 100%;
    margin: 30px 0;
  }
  @media (max-width: 1200px) {
    a {
      font-size: 12px;
    }
    button {
      font-size: 12px;
    }
  }
  @media (max-width: 1100px) {
    a {
      font-size: 10px;
    }
    button {
      font-size: 10px;
    }
  }
}
.finder_link {
  @extend .uk-button, .uk-button-primary, .uk-button-large;
}
.finder_link--world {
  background-image: url("../../assets/world.svg");
  background-repeat: no-repeat;
  background-position: 35%;
  background-size: 12%;
  padding-left: 35px !important;
  @media (max-width: 1200px) {
    background-position: 25%;
    padding-left: 25px !important;
  }
}
.finder_link--contact {
  background-image: url("../../assets/user.svg");
  background-repeat: no-repeat;
  background-position: 20%;
  background-size: 12%;
  padding-left: 35px !important;
  @media (max-width: 1200px) {
    background-position: 14%;
    padding-left: 17px !important;
  }
}
.finder_link--booking {
  background-image: url("../../assets/calendar.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20%;
  height: 43%;
  @media (max-width: 1200px) {
    background-size: 35%;
  }
}
.modal_contact_padding {
  padding: 20px 30px;
}
.modal_number {
  color: $global-muted-color;
  font-size: 14px !important;
  pointer-events: none;
  padding: 0 !important;
}
.padding-small {
  padding: 15px !important;
}
.margin-small-right {
  margin-right: 10px !important;

  @media (max-width: 1200px) {
    margin-right: 5px !important;
  }
  @media (max-width: 1100px) {
    margin-right: 0px !important;
  }
}
</style>
