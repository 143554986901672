import { render, staticRenderFns } from "./MemoryGame.vue?vue&type=template&id=5a89a00a&scoped=true&"
import script from "./MemoryGame.vue?vue&type=script&lang=js&"
export * from "./MemoryGame.vue?vue&type=script&lang=js&"
import style0 from "./MemoryGame.vue?vue&type=style&index=0&id=5a89a00a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a89a00a",
  null
  
)

export default component.exports